import {
  SelectBox,
  SelectBoxItem,
} from "@tremor/react";
import { useState } from "react";
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { screenshotBasePath } from '../../../commons/config/settings';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom'
import { 
  selectFilteredDeviceEvents,
  selectSelectedForSending 
} from '../campaignsSlice';
import { useAppSelector } from "../../../app/hooks";

const ModalDownload = (props: any) => {
  const { orderId } = useParams();
  const filteredDeviceEvents = useAppSelector(selectFilteredDeviceEvents(orderId || '0'));
  const selectedForSending = useAppSelector(selectSelectedForSending);

  const urlsArray: Array<string> = [];

  // Local state
  const [sreenshotType, setSreenshotType] = useState(1);

  filteredDeviceEvents.forEach(deviceEvent => {
    const date = deviceEvent.date;
    const screenshotUrlForKey = `${screenshotBasePath + deviceEvent.urlClippedBase}`;
    let screenshotUrl: string = '';
    if (sreenshotType === 1) {
      screenshotUrl = `${screenshotBasePath + deviceEvent.urlClippedBase}`;
    } else if (sreenshotType === 2) {
      screenshotUrl = `${screenshotBasePath + deviceEvent.urlClippedUI}`;
    } else {
      screenshotUrl = `${screenshotBasePath + deviceEvent.urlFullPage}`;
    }

    const key = btoa(`${screenshotUrlForKey}${date}`);
    selectedForSending.includes(key) && urlsArray.push(screenshotUrl);

  })

  const urls = urlsArray.map((url: string) => url);

  const handleDownload = async () => {
    const zip = new JSZip();
    for (const url of urls) {
      const fname = new URL(url).pathname.split('/').slice(-1)[0];
      const response = await fetch(url);
      const data = await response.blob();
      zip.file(fname, data);
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `screenshots.zip`);
    })
  }

  return (

    <div className="modal-download">

      <div className="modal-download__title">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#f96a85">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
        </svg>
        <span className="h3">Download</span>
      </div>

      <div className="modal-download__row selector">
        <span className="h3">Seleziona posizione:</span>
        <SelectBox
          value={sreenshotType}
          onValueChange={(value: any) => setSreenshotType(value)}
          defaultValue={1}
        >
          <SelectBoxItem value={1} text="Ritaglio Semplice" />
          <SelectBoxItem value={2} text="Ritaglio con Browser" />
          <SelectBoxItem value={3} text="Pagina Intera" />
        </SelectBox>
        {/* <Toggle
          color="zinc"
          defaultValue={1}
          handleSelect={(value) => console.log(value)}
        >
          <ToggleItem value={1} text="JPG" />
          <ToggleItem value={2} text="PNG" />
        </Toggle> */}

      </div>

      <div className="modal-download__row infos">
        <div className="modal-download__col">
          <span className="h3">Numero file</span>
          <span>{urlsArray.length}</span>
        </div>
        <div className="modal-download__col">
          <span className="h3">Dimensioni</span>
          <span>---</span>
        </div>
      </div>

      <div className="modal-download__row submit">
        <button
          onClick={
            () => urls?.length > 0 &&
              toast.promise(handleDownload(), {
                loading: 'Donwload...',
                success: <b>Screenshots scaricati!</b>,
                error: <b>C'è stato un errore nel download.</b>,
              })} className={`cta disabled`}>Download</button>
      </div>
    </div >
  )
}

export default ModalDownload