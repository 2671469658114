import './Alert.css';

const Alert = (props: any) => {
    return (
        <div className='pzo__alert alert'>
            <p className='alert__text'>{props.alertTxt}</p>
            <em className='alert__icon icon-alert'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#E74C3C">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                </svg>

            </em>
        </div>
    )
}

export default Alert