import { IOrder } from "./PaparazzoTypes";

const orderSortFn = (ordersSortCriteria: string, ordersSortDirection: string) => (orderA: IOrder, orderB: IOrder): number => {
  let _sort: any;
  switch (ordersSortCriteria) {

    case 'EVENTS_COUNT_TOTAL':
      _sort = (orderA.deviceEventsCount.total || 0) - (orderB.deviceEventsCount.total || 0);
      break;

    case 'ALPHABETICAL_CLIENT':
      _sort = (orderA.mnzClientName || 0) < (orderB.mnzClientName || 0) ? 1 : -1;
      break;

    case 'START_DATE':
      _sort = (orderA.startDate || 0) - (orderB.startDate || 0);
      break;

    case 'END_DATE':
      _sort = (orderA.endDate || 0) - (orderB.endDate || 0);
      break;

    default:
      _sort = 1;
  }

  return ordersSortDirection === 'DESC' ? _sort * -1 : _sort;
}

export default orderSortFn;