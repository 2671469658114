import { useState } from 'react';
import { SelectBox, SelectBoxItem } from '@tremor/react';
import { domainFromURL, isValidUrl, removeHttp } from '../../utils/utils';

const FormTestate = () => {

  // Local testata state
  const [testata, setTestata] = useState<any>({
    id: '-1',
    baseURL: '',
    priority: 1,
    type: 'DISPLAY',
    urls: [],
  });


  return (
    <>
      <h3 className="user-form__title">Crea una nuova testata</h3>
      <div
        className={`user-form__form testate-form__form bg-transparent-white`}
      >
        <div className="row">
          <label>
            <span>*</span>URL
          </label>
          <input
            type="text"
            value={testata.baseURL}
          // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          //   handleSetChanges('baseURL', e.target.value);
          // }}
          // onBlur={handleUrlError}
          />
          {/* {urlError && <Alert alertTxt={urlError} />} */}
        </div>
        <div className="row">
          <label>
            <span>*</span>Priorità
          </label>
          <SelectBox
            value={testata.priority}
          // onValueChange={(value: number) =>
          //   handleSetChanges('priority', value)
          // }
          >
            <SelectBoxItem value={1} text="Bassa" />
            <SelectBoxItem value={2} text="Media" />
            <SelectBoxItem value={3} text="Alta" />
          </SelectBox>
        </div>
        <div className="row">
          <label>
            <span>*</span>Tipologia
          </label>
          <SelectBox
            value={testata.type}
          //  onValueChange={(value: string) => handleSetChanges('type', value)}
          >
            <SelectBoxItem value="DISPLAY" text="Display" />
            <SelectBoxItem value="PREROLL" text="Preroll" />
          </SelectBox>
        </div>
        <div
          className={`cta ${!isValidUrl(testata.baseURL) ? 'disabled' : ''}`}
        // onClick={handleSaveTestata}
        >
          Salva
        </div>
      </div>
    </>
  );
};

export default FormTestate;
