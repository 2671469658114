import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { mongoEndpoint, mongoEventsCollection } from "../../commons/config/settings";
import { IDeviceEvent, IOrder } from "./PaparazzoTypes";
import ScreenshotCard from "./ScreenshotCard";

const Explorer = () => {
  const [events, setEvents] = useState([]);
  const { placement } = useParams();
  
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        `${mongoEndpoint}/events?collection=mongoEventsCollection&placement=${placement}`,
      );

      const events = result.data.reduce(
        (acc: Array<IDeviceEvent>, prev: IOrder) => [...acc, ...prev.deviceEvents.map(ev => ({...ev, order: prev.order}))], []
      )
      .filter((ev: IDeviceEvent) => placement && ev.placement?.includes(placement))
      .sort((evA: IDeviceEvent, evB: IDeviceEvent) => evA.time > evB.time ? -1 : 1);

      setEvents(events);
    };

    fetchData();
  }, []);

  return (
    <div className="order-details__screenshots screenshots">
      {
        events && events
          .filter((ev:IDeviceEvent) => !ev.isMarked)
          .map((event, k) => 
            <ScreenshotCard deviceEvent={event} key={k} />
          )
      }
      
    </div>
  );
}

export default Explorer;

