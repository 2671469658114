import { useState } from 'react';

const InputURL = ({
  initialValue,
}: {
  initialValue: string;
}) => {
  const [value, setValue] = useState(initialValue);

  return (
    <>
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </>
  );
};

export default InputURL;
