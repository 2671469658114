import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface FiltersState {
    isFiltersOpen: boolean,
    isAdvancedFiltersOpen: boolean
}

const initialState: FiltersState = {
    isFiltersOpen: false,
    isAdvancedFiltersOpen: false
};

export const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setFiltersOpen: (state, action: PayloadAction<boolean>) => {
            state.isFiltersOpen = action?.payload;
        },
        toggleFiltersOpen: (state) => {
            state.isFiltersOpen = !state.isFiltersOpen;
        },
        setAdvancedFiltersOpen: (state, action: PayloadAction<boolean>) => {
            state.isAdvancedFiltersOpen = action?.payload;
        },
        toggleAdvancedFiltersOpen: (state) => {
            state.isAdvancedFiltersOpen = !state.isAdvancedFiltersOpen;
        }
    },
});

export const { 
    setFiltersOpen, 
    toggleFiltersOpen,
    setAdvancedFiltersOpen,
    toggleAdvancedFiltersOpen
} = filtersSlice.actions;

export const selectFiltersState = (state: RootState) => state.filters;
export const selectIsFiltersOpen = createSelector([selectFiltersState], filterState => filterState.isFiltersOpen);
export const selectIsAdvancedFiltersOpen = createSelector([selectFiltersState], filterState => filterState.isAdvancedFiltersOpen);

export default filtersSlice.reducer;
