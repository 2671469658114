import { FormEvent, useEffect, useState } from 'react';
//import '../styles/Login.css';
import * as Realm from "realm-web";
import './Login.css';
import PaparazzoLogo from '../../commons/images/svgs/paparazzo_logo_vertical.svg';
import ManzoniLogo from '../../commons/images/manzoni_logo.png';
import { TextInput } from "@tremor/react";
import { Link } from 'react-router-dom';

const Reset = () => {
  const app = new Realm.App({ id: "paparazzoreporter-kroly" });

  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [tokenId, setTokenId] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [passResetted, setPassResetted] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const tokenId = params.get("tokenId");
    if (token && tokenId) {
      setToken(token);
      setTokenId(tokenId);
    }
  }, []);
  
  const handleReset = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (token && tokenId && password) {
      await app.emailPasswordAuth.resetPassword({
        password,
        token,
        tokenId,
      });
      setPassResetted(true);
    } else if (user) {
      await app.emailPasswordAuth.sendResetPasswordEmail({
        email: user
      });

      setEmailSent(true);
    }    
  }

  return (
    <div className='login reset'>

      <form className="form">
        <div className="form__header">
          <img src={PaparazzoLogo} alt="Paparazzo" className='app-logo' />
        </div>
        <div className="form__body">
          <span className='h1'>Reimposta password</span>

          {
            token && tokenId &&
            <TextInput
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Nuova Password"
                disabled={false}
                maxWidth="max-w-none"
                marginTop="mt-0"
              />
          }

          {
            (!token && !tokenId) &&
            <TextInput
              name="username"
              value={user}
              onChange={(e) => setUser(e.target.value)}
              placeholder="e-mail"
              icon={undefined}
              error={false}
              errorMessage=""
              disabled={false}
              maxWidth="max-w-none"
              marginTop="mt-0"
            />
          }
          
          <button className='cta'
            onClick={handleReset}>
            Invia
          </button>

          {
            emailSent &&
            
            <div className="err--message" style={{color: "red", marginTop: 20, textAlign: "center"}}>
              <p>Link per il reset della password inviato tramite email!</p>
            </div>
          }

          {
            passResetted &&
            
            <div className="err--message" style={{color: "red", marginTop: 20, textAlign: "center"}}>
              <p style={{color: "#008200!important", fontSize: 16}}>Password correttamente impostata</p>
            </div>
          }

            <div className="err--message" style={{color: "red", marginTop: 20, textAlign: "center"}}>
              <p><Link to="/login">Clicca qui per tornare alla pagina di Login</Link></p>
            </div>
        </div>
      </form>
    </div>

  );
}

export default Reset;