import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './app/store';
import Root from './commons/components/Root';
import MarcoApp from './features/marcoComponent/MarcoApp';
import './index.css';
import Dashboard from './commons/components/Dashboard';
import OrderDetails from './features/campaigns/OrderDetails';
import ActiveCampaigns from './commons/components/ActiveCampaigns';
import CampaignsHistory from './commons/components/CampaingsHistory';
import UsersManagement from './commons/components/UsersManagement';
import WorkInProgress from './commons/components/WorkInProgress';
import Explorer from './features/campaigns/Explorer';
import { AuthProvider, RequireAuth } from 'react-auth-kit'
import Login from './features/login/Login';
import Reset from './features/login/Reset';
import UserForm from './features/users/UserForm';
import GestioneTestate from './commons/components/GestioneTestate';
import FormTestate from './features/testate/FormTestate';


const container = document.getElementById('root')!;
const root = createRoot(container);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/login",
        element: <Login />
      },
      {
        path: "/user/reset",
        element: <Reset />
      },
      {
        path: "dashboard",
        element: <RequireAuth loginPath={'/login'}><Dashboard /></RequireAuth>,
      },
      {
        path: "campagne",
        element: <RequireAuth loginPath={'/login'}><ActiveCampaigns /></RequireAuth>,
      },
      {
        path: "storico",
        element: <RequireAuth loginPath={'/login'}><CampaignsHistory /></RequireAuth>,
      },
      {
        path: "explorer/:placement",
        element: <RequireAuth loginPath={'/login'}><Explorer /></RequireAuth>,
      },
      {
        path: '/admin/testate/',
        element: (
          <RequireAuth loginPath={'/login'}>
            <GestioneTestate />
          </RequireAuth>
        ),
        children: [
          {
            path: 'nuova-testata',
            element: (
              <RequireAuth loginPath={'/login'}>
                <FormTestate />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: "/admin/utenze/",
        element: <RequireAuth loginPath={'/login'}><UsersManagement /></RequireAuth>,
        children: [
          {
            path: "modifica-utente/:userId",
            element: <RequireAuth loginPath={'/login'}><UserForm /></RequireAuth>,
          },
          {
            path: "nuovo-utente",
            element: <RequireAuth loginPath={'/login'}><UserForm /></RequireAuth>,
          }
        ]
      },
      {
        path: "marcoapp",
        element: <MarcoApp />,
      },
      {
        path: "orderpage/:orderId",
        element: <OrderDetails />,
      },
    ],
  }

]);


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider authType={'cookie'}
        authName={'_auth'}
        cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === "https:"}>
        <RouterProvider router={router} />
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);

