import { useAppDispatch } from "../../app/hooks";
import { setSelectionEnabled, resetSelectedForSend, selectAllForSend } from './campaignsSlice';

const SelectionBar = (props: any) => {
    const dispatch = useAppDispatch();

    return (
        <div className='selection-bar'>
            <span className='h1'>Seleziona gli elementi da inviare
                <sup>
                    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" opacity={0.5}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg>
                </sup>
            </span>
            <div className='selection-bar__row'>
                <div className='selection-bar__col'>
                    <button
                        className="cta cta--outlined"
                        onClick={() => dispatch(setSelectionEnabled())}>
                        Esci
                    </button>
                    <div className="selection-bar__selected-items">
                        <span>Elementi selezionati: {props.itemsSelected}/{props.total}</span>
                    </div>
                </div>
                <div className='selection-bar__col'>
                    <button
                        className="cta cta--outlined"
                        onClick={() => dispatch(resetSelectedForSend())}>
                        <span>Annulla selezione</span>
                    </button>
                    <button
                        className="cta cta"
                        onClick={() => dispatch(selectAllForSend(props.orderId))}>
                        <span>Seleziona tutto</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SelectionBar