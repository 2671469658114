import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectIsAdvancedFiltersOpen, toggleAdvancedFiltersOpen } from './FiltersSlice';
import { useEffect, useState } from 'react';
import { IDeviceEvent } from '../campaigns/PaparazzoTypes';
import { setEventFilter, resetEventFilter, fetchCampaigns, selectTxtMongo } from '../campaigns/campaignsSlice';
import {
    TextInput,
    MultiSelectBox,
    MultiSelectBoxItem,
    SelectBox,
    SelectBoxItem,
    DateRangePicker,
    DateRangePickerValue
} from "@tremor/react";
import './Filters.css';

const AdvancedFilters = ({ deviceEvents, order }: { deviceEvents: Array<IDeviceEvent>, order: any }) => {
    const isAdvancedFilterOpen = useAppSelector(selectIsAdvancedFiltersOpen);
    const dispatch = useAppDispatch();
    const uniquePlacement = deviceEvents?.filter((item: IDeviceEvent, index: number, self: any) => index === self.findIndex((t: any) => t.placement === item.placement));
    const txtMongo = useAppSelector(selectTxtMongo);
    // Local state
    const [txtQuery, setTxtQuery] = useState(txtMongo);
    const [placements, setPlacements] = useState([]);
    const [device, setDevice] = useState('');
    const [status, setStatus] = useState('');
    const [selectedDates, setSelectedDates] = useState<DateRangePickerValue>([]);

    const getFilteredObject = () => {
        return {
            txtQuery,
            device,
            placements,
            status,
            selectedDates: [
                selectedDates[0] && new Date(selectedDates[0]).getTime(),
                selectedDates[1] && new Date(selectedDates[1]).getTime()
            ],
        };
    }

    const handlePlacementSelection = (options: any) => {
        setPlacements(options);
    }

    const handleDeleteFilters = () => {
        setTxtQuery('');
        setPlacements([]);
        setDevice('');
        setSelectedDates([]);
        dispatch(resetEventFilter());
    }

    useEffect(() => {
        dispatch(setEventFilter(getFilteredObject()));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={isAdvancedFilterOpen ? 'filters filters--open' : 'filters'}>

            <div className='filters__toggle' onClick={() => dispatch(toggleAdvancedFiltersOpen())}>

                <div className='filters__label'>
                    <span className='filters__icon'>
                        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#6a163b">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                        </svg>
                    </span>
                    <span className='filters__title'>
                        {
                            isAdvancedFilterOpen ? 'Nascondi ricerca avanzata' : 'Mostra ricerca avanzata'
                        }
                    </span>
                </div>

                <span className='filters__arrow'>
                    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#6a163b">
                        <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clipRule="evenodd" />
                    </svg>
                </span>
            </div>

            <div className='filters__dropdown filter'>

                <div className='filter__row'>

                    <div className='filter__input text'>
                        <label>Ordine o nome cliente (anche parziali)</label>
                        <TextInput
                            name=""
                            value={txtQuery}
                            onChange={(e) => setTxtQuery(e.target.value)}
                            placeholder=""
                            icon={undefined}
                            error={false}
                            errorMessage=""
                            disabled={false}
                            maxWidth="max-w-none"
                            marginTop="mt-0"
                        />
                    </div>

                    <div className='filter__input date-picker'>
                        <label>Seleziona data</label>
                        <DateRangePicker
                            defaultValue={selectedDates}
                            value={selectedDates}
                            onValueChange={setSelectedDates}
                            placeholder=""
                            //@ts-ignore
                            enableRelativeDates={true}
                            enableYearPagination={false}
                            defaultStartDate={null}
                            defaultEndDate={null}
                            defaultRelativeFilterOption={null}
                            minDate={null}
                            maxDate={null}
                            color="blue"
                            maxWidth="max-w-none"
                            marginTop="mt-0"
                        />
                    </div>

                </div>

                <div className='filter__row'>

                    <div className='filter__input select'>
                        <label>Stato</label>
                        <SelectBox
                            value={status}
                            onValueChange={(value: string) => setStatus(value)}
                            placeholder={'Seleziona...'}
                        >
                            <SelectBoxItem value={''} text="Tutti" />
                            <SelectBoxItem value={'delivering'} text="Attivo" />
                            <SelectBoxItem value={'completed'} text="Completato" />
                        </SelectBox>
                    </div>

                    <div className='filter__input multi-select disabled'>
                        <label>Posizione</label>
                        <MultiSelectBox
                            value={placements}
                            onValueChange={handlePlacementSelection}
                            placeholder="Attualmente non disponibile"
                            icon={undefined}
                            maxWidth="max-w-none"
                            marginTop="mt-0"
                        >
                            {
                                uniquePlacement?.map((item: IDeviceEvent, idx: number) => {
                                    return (
                                        <MultiSelectBoxItem
                                            key={idx}
                                            text={`${item.placement}`}
                                            value={item.placement}
                                        />
                                    )
                                })
                            }
                        </MultiSelectBox>
                    </div>

                    <div className='filter__input select'>
                        <label>Device</label>
                        <SelectBox
                            value={device}
                            onValueChange={(value: string) => setDevice(value)}
                            placeholder={'Seleziona...'}
                        >
                            <SelectBoxItem value={''} text="Tutti" />
                            <SelectBoxItem value={'DESKTOP'} text="Desktop" />
                            <SelectBoxItem value={'TABLET'} text="Tablet" />
                            <SelectBoxItem value={'MOBILE'} text="Mobile" />
                        </SelectBox>

                    </div>
                </div>

                <div className='filter__row'>
                    <button
                        onClick={handleDeleteFilters}
                        className='cta cta--outlined'>Elimina filtri
                    </button>
                    <button
                        onClick={() => dispatch(
                            fetchCampaigns({
                                limit: 200,
                                txtQuery: getFilteredObject().txtQuery.toUpperCase(),
                                device: getFilteredObject().device.toUpperCase(),
                                status: getFilteredObject().status,
                                from: getFilteredObject().selectedDates[0] || 0,
                                to: getFilteredObject().selectedDates[1] || 0,
                                forceFetch: true
                            })
                        )}
                        className='cta'>Applica filtri</button>
                </div>
            </div>
        </div>
    )
}

export default AdvancedFilters