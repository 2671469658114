import { useAppDispatch } from '../../../app/hooks';
import {
    setModalOpen,
} from '../../campaigns/campaignsSlice';
import { disableUser } from '../../users/UsersSlice';


const ModalDeleteUser = ({ userName, userEmail }:
    { userName: string, userEmail: string }) => {

    const dispatch = useAppDispatch();


    return (
        <div className='modal-delete-user'>
            <span className="h2">Sei sicuro di voler eliminare questa utenza?</span>

            <div className="user">
                <span className="user__name">{userName}</span>
                <span className="user__email">{userEmail}</span>
            </div>

            <div className="ctas">
                <div className="cta" onClick={() => dispatch(setModalOpen())}>
                    <span>Annulla</span>
                </div>
                <div className="cta cta__delete"  onClick={() => dispatch(disableUser(userEmail))}>
                    <span>Elimina</span>
                </div>
            </div>
        </div>
    )
}

export default ModalDeleteUser