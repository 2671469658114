import { useEffect } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { setModalOpen } from '../campaignsSlice';
import { useLocation } from 'react-router-dom';
import './Modal.css'

//@ts-ignore
const Modal = ({ order, state, children }) => {

    const location = useLocation();

    const dispatch = useAppDispatch();

    const handleKeyDown = (event: any) => {
        if (event.key === "Escape") {
            dispatch(setModalOpen())
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return (
        <div
            className={state ? 'pzo__modal pzo__modal--open' : 'pzo__modal'}>
            <div className={`modal ${location.pathname == '/admin/utenze' && 'modal__modal-delete-user'}`}>
                <div className='modal__content'>
                    {
                        location.pathname !== '/admin/utenze' &&
                        <div className="modal__header">
                            <div className="modal__title">
                                <span>Order campagna</span>
                                <span><strong>{order.order}</strong></span>
                            </div>
                            <svg
                                onClick={() => dispatch(setModalOpen())}
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#ffffff">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                    }
                    <div className="modal__body">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal