import { useEffect } from 'react';
import Campaign from '../../features/campaigns/Campaign';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectOrders, fetchCampaigns, selectTxtMongo } from '../../features/campaigns/campaignsSlice';
import { setAdvancedFiltersOpen } from '../../features/filters/FiltersSlice';
import AdvancedFilters from '../../features/filters/AdvancedFilters';

const CampaignsHistory = () => {
  const orders = useAppSelector(selectOrders);
  const dispatch = useAppDispatch()
  const txtMongo = useAppSelector(selectTxtMongo);

  useEffect(() => {
    setTimeout(() => {
      if (txtMongo && txtMongo !== ""){
        dispatch(
          fetchCampaigns({
            limit: 200,
            txtQuery: txtMongo,
            forceFetch: true
          })
        );
      } else {
        dispatch(
          fetchCampaigns({
            limit: 200,
            txtQuery: "___notavalidquery__",
            forceFetch: true
          })
        );
      }
    }, 200);
    
    dispatch(setAdvancedFiltersOpen(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='dashboard active-campaigns storico'>

      <h2>Tutte le campagne</h2>

      <AdvancedFilters
        deviceEvents={[]}
        order={''} />

      <span className='campaigns__total'>Risultati: {orders.length}</span>

      <div className='campaigns'>
        <Campaign orders={orders} />
      </div>

    </div>
  )
}

export default CampaignsHistory