import React from 'react'
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { setName, setEmailAlert, selectMarcoState, setPassword, setPasswordAlert, loginState } from './marcoSlice';

const MarcoApp = () => {

    const rootState = useAppSelector(selectMarcoState)
    const dispatch = useAppDispatch();

    return (
        <>
            <input
                type="email"
                name="email"
                id="email"
                placeholder='Email'
                onChange={(e) => dispatch(setName(e.target.value))}
                onBlur={() => dispatch(setEmailAlert(rootState.email))}
            />
            <input
                type="password"
                name="password"
                id="password"
                placeholder='Password'
                onChange={(e) => dispatch(setPassword(e.target.value))}
                onBlur={() => dispatch(setPasswordAlert(rootState.password))}
            />

            <input
                type="submit"
                name="submit"
                id="submit"
                disabled={
                    rootState.email !== '' &&
                        rootState.password !== '' ? false : true
                }
                onClick={() => dispatch(loginState())}

            />

            <p>
                Email Alert:
                {rootState.emailAlert}
            </p>

            <p>
                Password Alert:
                {rootState.passwordAlert}
            </p>

            <p>
                Login State:
                {rootState.loginAlert}
            </p>
        </>
    )
}

export default MarcoApp