import { NavLink } from "react-router-dom";
import '../styles/Sidebar.css';
import { useSignOut, useIsAuthenticated } from 'react-auth-kit'
import { Button } from "@tremor/react";
import {useAuthUser} from 'react-auth-kit'

const Sidebar = () => {
  const signOut = useSignOut();
  const isAuthenticated = useIsAuthenticated();
  const auth = useAuthUser();
  const user = auth();

  return (
    <div id="pzo__sidebar">
      <div className="pzo__sidebar-wrapper">
        <h1>Paparazzo</h1>
        <div className="pzo__logo">
          <span className="icon__logo logo-full"></span>
        </div>
        <div className="pzo__sidebar-flex">
          <nav>
            <ul>
              <li>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to={`dashboard`}>
                  <span className="pzo__nav-link">Dashboard</span>
                  <span className="pzo__icon icon-home"></span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to={`campagne`}>
                  <span className="pzo__nav-link">Campagne attive</span>
                  <span className="pzo__icon icon-badge"></span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to={`storico`}>
                  <span className="pzo__nav-link">Ricerca avanzata</span>
                  <span className="pzo__icon icon-folder"></span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to={`admin/testate`}>
                  <span className="pzo__nav-link">Gestione testate</span>
                  <span className="pzo__icon icon-newspaper"></span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to={`admin/utenze`}>
                  <span className="pzo__nav-link">Gestione utenze</span>
                  <span className="pzo__icon icon-users"></span>
                </NavLink>
              </li>
              
            </ul>
          </nav>
          <nav>
            <ul className="pzo__sidebar-user-btns">
              {
                isAuthenticated() &&
                <li onClick={() => signOut()}>
                  <span className="pzo__nav-link">Esci</span>
                  <span className="pzo__icon icon-exit"></span>
                </li>
              }
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
};

export default Sidebar;