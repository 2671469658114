export function checkEmailValidity(users, email) {

    const emailRegex = new RegExp("^(?=.{1,64}@)[A-Za-z0-9_-]+(\\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$");

    if (emailRegex.test(email)) {

        let findEmail = users.find(user => user.email === email);

        if (findEmail) {
            return '';
        } else {
            return 'Mail not found';
        }

    } else if (email === '') {
        return 'Email is required';
    }

    return "Invalid email.";
}


export function checkPasswordValidity(password) {

    if (password !== '') {
        return '';
    } else {
        return 'Password is required';
    }
}


export function handleLoginSubmit(users, email, password) {
    let findUser = users.find(user => user.email === email);
    console.log(findUser)
    if (findUser.password === password) {
        return 'Login OK';
    } else {
        return 'Invalid Email or Password';
    }
}
