import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { TextInput } from "@tremor/react";
import {
    setFastSearch,
    setMongoTxtQuery
} from '../../features/campaigns/campaignsSlice';
import '../styles/Searchbar.css';
import { useNavigate } from "react-router-dom";

const Searchbar = ({ isFastSearch }: { isFastSearch: boolean }) => {
    const dispatch = useAppDispatch();
    const srcFast = useAppSelector(state => state.campaigns.fastSearch);
    const srcMongo = useAppSelector(state => state.campaigns.mongoTxtQuery);
    const navigate = useNavigate();

    const handleSrc = (e: React.FormEvent<HTMLInputElement>) => {
        if (true === isFastSearch) {
            dispatch(setFastSearch(e.currentTarget.value));
        } else {
            dispatch(setMongoTxtQuery(e.currentTarget.value));
        }
    }

    const handleFind = () => {
        if (false === isFastSearch) {
            navigate("/storico");
        }
    }

    const clearSrc = () => {
        if (true === isFastSearch) {
            dispatch(setFastSearch(''));
        } else {
            dispatch(setMongoTxtQuery(''));
        }
    }

    return (
        <div className='pzo__searchbar searchbar'>
            <div className='searchbar__icon' onClick={handleFind}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#ffffff" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                </svg>
            </div>
            <div
                onClick={clearSrc}
                className={`searchbar__clear ${srcFast !== '' || srcMongo !== '' && 'visible'}`}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#ff0099" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>
            <TextInput
                placeholder="Cerca per ordine o nome cliente (anche parziali)"
                onChange={handleSrc} value={isFastSearch ? srcFast : srcMongo} />
        </div>
    )
}

export default Searchbar