import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface SidebarState {
    isSidebarOpen: boolean,
}

const initialState: SidebarState = {
    isSidebarOpen: true,
};

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setSidebarOpen: (state) => {
            state.isSidebarOpen = !state.isSidebarOpen;
        }
    },
});

export const { setSidebarOpen } = sidebarSlice.actions;

export const selectSidebarState = (state: RootState) => state.sidebar;

export default sidebarSlice.reducer;
