/*
Per la logica usata nei selectors si veda
https://redux.js.org/usage/deriving-data-selectors
e
https://react-redux.js.org/api/hooks
*/

import { 
  createAsyncThunk, 
  createSlice, 
  PayloadAction, 
  createSelector } 
from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import {
  suspendUser,
getUsers, newUser
} from './UsersAPI';

export interface User {
  id: number,
  custom_data: {
    role?: string,
    name: string,
    disabled?: boolean
  },
  data: {
    email: string
  }
}

export interface UserState {
  list: Array<User>,
  status: string,
  error: string,
  currentUser?: User,
  sorting: string,
  sortingOrder: string,
  userFilter: string
}

const initialState: UserState = {
  list: [],
  status: "idle",
  sorting: 'NAME',
  error: "",
  sortingOrder: "ASC",
  userFilter: ""
};

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const response = await getUsers();
  return response;
});

export const createUser = createAsyncThunk('users/createUser', async (data: User) => {
  try {
    const response = await newUser(data);
    
    return response;
  } catch (e) {
    throw new Error('Error creating user');
  }
});

export const disableUser = createAsyncThunk('users/disableUser', async (email: string) => {
  try {
    const response = await suspendUser(email);
    
    return response;
  } catch (e) {
    throw new Error('Error creating user');
  }
});


export const campaignsSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSorting: (state, action: PayloadAction<string>) => {
      if (state.sorting === action.payload) {
        state.sortingOrder = state.sortingOrder === 'ASC' ? 'DESC' : 'ASC';
      } else {
        state.sorting = action.payload;
      }
    },
    setSortingOrder: (state, action: PayloadAction<string>) => {
      state.sortingOrder = action.payload;
    },
    setUserFilter: (state, action: PayloadAction<string>) => {
      state.userFilter = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createUser.pending, (state) => {
        state.error = "";
        state.status = 'loading';
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.status = 'idle';
        //TODO: FARLO CON IL ROUTER!!
        //@ts-ignore
        if (action.payload.error) {
          //@ts-ignore
          console.log(action.payload.error);
          //@ts-ignore
          state.error = action.payload.error;
        } else {
          window.location.href = '/admin/utenze';
        }
      })
      .addCase(createUser.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(disableUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(disableUser.fulfilled, (state, action) => {
        state.status = 'idle';
        //TODO: FARLO CON IL ROUTER!!
        window.location.href = '/admin/utenze';
      })
      .addCase(disableUser.rejected, (state) => {
        state.status = 'failed';
      });
  },
});


export const {
  setSorting,
  setSortingOrder,
  setUserFilter
} = campaignsSlice.actions;

const selectUsersStateList = (state: RootState) => state.users.list;

export const selectSorting = (state: RootState) => state.users.sorting;
export const selectSortingOrder = (state: RootState) => state.users.sortingOrder;
export const selectUserFilter = (state: RootState) => state.users.userFilter;

export const selectUsersList = createSelector(
  [selectUsersStateList, selectSorting, selectSortingOrder, selectUserFilter],
  (usersList, sorting, order, userFilter) => [...usersList]
    .filter((user: User) => user.custom_data.name.toLowerCase().includes(userFilter) || user.data.email.includes(userFilter))
    .sort((a, b) => {
      switch (sorting) {
        case 'NAME':
          return order === 'ASC' ? a.data.email.localeCompare(b.data.email) : b.data.email.localeCompare(a.data.email);
        case 'EMAIL':
          return order === 'ASC' ? a.data.email.localeCompare(b.data.email) : b.data.email.localeCompare(a.data.email);
        case 'ROLE':
          return order === 'ASC' ? a.data.email.localeCompare(b.data.email) : b.data.email.localeCompare(a.data.email);
        default: 
          return 1;
      }  
}));

const selectUserId = (state: RootState, userId: number) => userId;
export const selectStatus = (state: RootState) => state.users.status;
export const selectError = (state: RootState) => state.users.error;

export const selectUser = createSelector(
  [selectUsersList, selectUserId],
  (usersList, userId) => usersList.filter((user) => user.id === userId)
);

export default campaignsSlice.reducer;
