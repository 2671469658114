import { Link, Outlet, useLocation } from 'react-router-dom';
import TableTestate from '../../features/testate/TableTestate';
import '../../features/testate/testate.css';

const GestioneTestate = () => {
  const location = useLocation();


  return (
    <div className="dashboard users-management">
      <div className="row">
        <h2>Gestione testate</h2>

        {location.pathname === '/admin/testate' && (
          <Link to={'/admin/testate/nuova-testata'}>
            <div className="cta">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
                />
              </svg>
              <span>Crea nuova testata</span>
            </div>
          </Link>
        )}
      </div>

      {location.pathname === '/admin/testate' && <TableTestate />}

      <Outlet />
    </div>
  );
};

export default GestioneTestate;

