import Campaign from '../../features/campaigns/Campaign';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectOrdersByFastSearch, fetchCampaigns } from '../../features/campaigns/campaignsSlice';
import Searchbar from './Searchbar';

const ActiveCampaigns = () => {
  /*
  const activeOrders = useAppSelector(
    (state) => selectOrdersByStatus(state, 'delivering')
  );
  */

  const dispatch = useAppDispatch();
  const orders = useAppSelector(selectOrdersByFastSearch)

  //const orders = useAppSelector(selectOrders);
  //const lastShootedOrders: Array<IOrder> = useAppSelector(selectLastShootedOrders);

  return (
    <div className='dashboard active-campaigns'>

      <h2>Campagne attive</h2>


      <h3>Ricerca rapida</h3>
      <Searchbar isFastSearch={true} />

      <div className="campaigns__update">
        <span className='campaigns__total'>Risultati: {orders.length}</span>
        <button
          onClick={() => dispatch(fetchCampaigns({ limit: 600, forceFetch: true, status: 'delivering' }))}
          className="cta">
          Aggiorna
        </button>
      </div>

      <div className='campaigns'>
        <Campaign orders={orders} />
      </div>

    </div>
  )
}

export default ActiveCampaigns