import { useState, useEffect } from 'react';
import { screenshotBasePath } from '../../../commons/config/settings';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import SwiperCore, { Navigation, Pagination, Keyboard } from "swiper";
SwiperCore.use([Pagination]);

const ModalSlideshow = (props: any) => {

    const deviceEvent = props.deviceEvent;

    // Local state
    const [swiper, setSwiper] = useState(0);
    const [activeUrl, setActiveUrl] = useState('')

    let screenshotInfo: string = "";

    if (swiper === 0) {
        screenshotInfo = 'Ritaglio Semplice';
    } else if (swiper === 1) {
        screenshotInfo = 'Ritaglio con Browser';
    } else {
        screenshotInfo = 'Fullpage';
    }

    useEffect(() => {
        if (swiper === 0) {
            setActiveUrl(screenshotBasePath + deviceEvent.urlClippedBase);
        } else if (swiper === 1) {
            setActiveUrl(screenshotBasePath + deviceEvent.urlClippedUI);
        } else {
            setActiveUrl(screenshotBasePath + deviceEvent.urlFullPage);
        }
    });

    const handleDownload = async () => {
        const zip = new JSZip();
        const fname = new URL(activeUrl).pathname.split('/').slice(-1)[0];
        const response = await fetch(activeUrl);
        const data = await response.blob();
        zip.file(fname, data);

        zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, `screenshot.zip`);
        })
    }

    return (
        <div className="modal-slideshow">
            <div className='screenshot__info-bar'><span>{screenshotInfo}</span></div>
            <Swiper
                navigation={true}
                pagination={true}
                keyboard={true}
                //autoHeight={true}
                onSlideChange={(slide) => setSwiper(slide.activeIndex)}
                modules={[Navigation, Keyboard]}
                className="mySwiper screenshot__swiper">
                <SwiperSlide>
                    <img src={screenshotBasePath + deviceEvent.urlClippedBase} alt="Screenshot" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={screenshotBasePath + deviceEvent.urlClippedUI} alt="Screenshot" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={screenshotBasePath + deviceEvent.urlFullPage} alt="Screenshot" />
                </SwiperSlide>
            </Swiper>
            <button className='cta cta-negative' onClick={handleDownload}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#6a163b">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
                </svg>
                <span>Download</span>
            </button>
        </div>
    )
}

export default ModalSlideshow