import { createSlice, PayloadAction } from "@reduxjs/toolkit"
//import { IMarcoData } from "./marcoTypes";
import { RootState, } from '../../app/store';
import { checkEmailValidity, checkPasswordValidity, handleLoginSubmit } from "./utils";

export interface marcoState {
    // persons: Array<IMarcoData>,
    email: string,
    emailAlert: string,
    password: string,
    passwordAlert: string,
    loginAlert: string,
    users: Array<{
        id: number,
        email: string,
        password: string
    }>,
}

const initialState: marcoState = {
    email: '',
    emailAlert: '',
    password: '',
    passwordAlert: '',
    loginAlert: '',
    users: [
        {
            id: 1,
            email: "marco.brambilla@keyformat.com",
            password: "MarcoB"
        },
        {
            id: 2,
            email: "test@test.it",
            password: "Ciaone"
        }
    ]
}

export const marcoSlice = createSlice({
    name: 'marco',
    initialState,
    reducers: {
        setName: (state, action: PayloadAction<string>) => {
            state.email = action.payload
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload
        },
        setEmailAlert: (state, action: PayloadAction<string>) => {
            state.emailAlert = checkEmailValidity(state.users, action.payload)
        },
        setPasswordAlert: (state, action: PayloadAction<string>) => {
            state.passwordAlert = checkPasswordValidity(action.payload)
        },
        loginState: (state) => {
            state.loginAlert = handleLoginSubmit(state.users, state.email, state.password)
        }
    }
})


export const { setName, setPassword, setPasswordAlert, setEmailAlert, loginState } = marcoSlice.actions;
export const selectMarcoState = (state: RootState) => state.marco;
export default marcoSlice.reducer;

