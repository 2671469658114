import axios from 'axios';
import * as Realm from "realm-web";

import {
  mongoEndpoint,
  mongoDB,
  mongoEventsCollection,
} from '../../commons/config/settings';
import { User } from './UsersSlice.js';

const mongoQueryData = {
  "collection": mongoEventsCollection,
  "database": mongoDB,
  "dataSource": "Cluster0"
};

const {
  BSON: { ObjectId },
} = Realm;


export async function getUsers() {
  const result = await axios.get(`${mongoEndpoint}/users`);

  return result.data.filter((user: User) => !user.custom_data?.disabled);
}

export async function newUser(data: User): Promise<boolean|{error: string}> {
  const app = new Realm.App({ id: "paparazzoreporter-kroly" });
  const email = data.data.email;
  const before = email.split('@')[0];
  const password = `${before}@paparazzo`;
  const mongo = app.currentUser?.mongoClient('mongodb-atlas');
  const collection = mongo?.db('Paparazzo').collection('Users');

  try {
    await app.emailPasswordAuth.registerUser({ email, password });
    const result = await collection?.insertOne(data);
    await app.emailPasswordAuth.sendResetPasswordEmail({email});
    console.log(result);
    return true;
  } catch (e) {
    //@ts-ignore
    return {error: e.errorCode};
  }
}

export async function suspendUser(email: string): Promise<boolean> {
  const app = new Realm.App({ id: "paparazzoreporter-kroly" });
  const mongo = app.currentUser?.mongoClient('mongodb-atlas');
  const collection = mongo?.db('Paparazzo').collection('Users');

  try {
    const result = await collection?.updateOne(
      {"data.email": email},
      {$set: {"custom_data.disabled": true}}
    );

    console.log(result);

    return true;
  } catch (e) {
    throw new Error(`Paparazzo: errore cancellazione utente`);
  }
}
